import * as React from "react"
import { Link } from "gatsby"
import SEO from "../components/SEO";
// markup
const NotFoundPage = () => {
  return (
    <main >
      <SEO blog={false} append={true} title={"NOT FOUND (404)"} description={"Page not found! Error 404! Please visit https://gedonlinetest.us"}/>
      PAGE NOT FOUND. PLEASE VISIT <Link to={'/'}>Home Page</Link>
    </main>
  )
}

export default NotFoundPage
